export default {
  namespaced: true,
  state: () => {
    return {
      authentication_id: "",
    }
  },
  mutations: {
    setAuthentication_id(state, id) {
      state.authentication_id = id
    },
  },
}
