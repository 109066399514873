import { myMerchantDetails } from "@/api/commercial";
export default {
  namespaced: true,
  state: () => {
    return {
      info: {},
      basicInfo: {},
      basicPic: {},
      accountInfo: {},
      accountPic: {},
      rateInfo: {},
      picInfo: {},
      componentName: ["rate", "account", "basic", "Picture"],
      alter: '',
    };
  },
  mutations: {
    setInfo(state, info) {
      state.info = info;
    },
    setBasicInfo(state, info) {
      state.basicInfo = info;
    },
    setBasicPic(state, basicPic) {
      state.basicPic = basicPic;
    },

    setAccountInfo(state, info) {
      state.accountInfo = info;
    },
    setAccountPic(state, accountPic) {
      state.accountPic = accountPic;
    },
    setRateInfo(state, info) {
      state.rateInfo = info;
    },
    setPicInfo(state, info) {
      state.picInfo = info;
    },
    setComponentName(state, componentName) {
      state.componentName = componentName;
    },
    //缓存变更申请表
    setAlter(state, info) {
      state.alter = info;
    }
  },
  actions: {
    async getMyMerchantDetails(context, id) {
      const { data } = await myMerchantDetails({ authentication_id: id });
      context.commit("setInfo", data);
    },
  },
};
