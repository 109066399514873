export default {
  data() {
    return {
      home: require("@/assets/img/首页 拷贝 3.png"),
      home1: require("@/assets/img/首页 拷贝 3 (2).png"),
      information: require("@/assets/img/应用中心 拷贝 3(1).png"),
      information1: require("@/assets/img/应用中心 拷贝 3@2x(3).png"),
      use: require("@/assets/img/应用.png"),
      use1: require("@/assets/img/应用@2x (2).png"),
      my: require("@/assets/img/微信图片_20230323115628.png"),
      my1: require("@/assets/img/微信图片_20230323115648.png")
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === "/login") {
      next(vm => {
        vm.$store.commit("tabbar/updateActive", 0);
      });
    }
    next();
  },
  methods: {
    changeActive(val) {
      this.$store.commit("tabbar/updateActive", val);
    }
  }
};