import axios from "axios";
import store from "@/store/index";
import Vue from "vue";
import router from "@/router";

const vm = new Vue();
var loading;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    loading = vm.$toast.loading({
      forbidClick: true,
    });
    const token = store.state.login.token;
    if (token) {
      config.headers.token = token;
    }
    config.headers["content-type"] = "application/x-www-form-urlencoded";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      return router.replace("/login");
    }
    loading.clear();
    if (response.data.code !== 0) {
      // new Vue().$notify({ type: 'warning', message: response.data.message });
      vm.$toast(response.data.message);
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
