import request from "@/utils/request"
import qs from "qs"

export function myMerchant(data) {
  return request({
    url: "/index.php?m=Agent&c=Merchants&a=myMerchant",
    method: "post",
    data: qs.stringify(data),
  })
}

export function myMerchantDetails(data) {
  return request({
    url: "/index.php?m=Agent&c=Merchants&a=myMerchantDetails",
    method: "post",
    data: qs.stringify(data),
  })
}

export function myRateDetails(data) {
  return request({
    url: "/index.php?m=Agent&c=Merchants&a=rateDetails",
    method: "post",
    data: qs.stringify(data),
  })
}


//到账方式列表
export function receiptPeriod(data) {
  return request({
    url: "/index.php?m=Agent&c=Merchants&a=selectReceiptPeriod",
    method: "post",
    data: qs.stringify(data),
  })
}

//设置到账方式
export function updateReceiptPeriod(data) {
  return request({
    url: "/index.php?m=Agent&c=Merchants&a=updateReceiptPeriod",
    method: "post",
    data: qs.stringify(data),
  })
}

//到账方式详情
export function ReceiptPeriodDetails(data) {
  return request({
    url: "/index.php?m=Agent&c=Merchants&a=ReceiptPeriodDetails",
    method: "post",
    data: qs.stringify(data),
  })
}

// 下载变更申请表模版
export function downLoadPerformance(data) {
  return request({
    url: "/index.php?m=Agent&c=ChangeMerc&a=performanceTemplate",
    method: "post",
    data: qs.stringify(data),
  });
}
