import Vue from 'vue'
import VueRouter from 'vue-router'
import Tabbar from '@/tabbar/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/application/home',
  },

  {
    path: '/warehouseentry/:id?',
    name: 'warehouseentry',
    component: () => import('@/views/warehouseentry/index.vue'),
  },
  {
    path: '/application',
    component: Tabbar,
    redirect: '/application/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/information/index.vue'),
      },
      {
        path: 'use',
        name: 'use',
        component: () => import('@/views/use/index.vue'),
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('@/views/my/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/team/index.vue'),
  },

  {
    path: '/createTeam',
    name: 'createTeam',
    component: () => import('@/views/createTeam/index.vue'),
  },
  {
    path: '/teamMember',
    name: 'teamMember',
    component: () => import('@/views/teamMember/index.vue'),
  },
  {
    path: '/teamMparticulars',
    name: 'teamMparticulars',
    component: () => import('@/views/teamMparticulars/index.vue'),
  },
  {
    path: '/inviteTeamItem',
    name: 'inviteTeamItem',
    component: () => import('@/views/inviteTeamItem/index.vue'),
  },
  {
    path: '/performanceDetails',
    name: 'performanceDetails',
    component: () => import('@/views/performanceDetails/index.vue'),
  },
  {
    path: '/memberDetails',
    name: 'memberDetails',
    component: () => import('@/views/memberDetails/index.vue'),
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: () => import('@/views/commercial/index.vue'),
  },
  {
    path: '/commercialInfo',
    name: 'commercialInfo',
    component: () => import('@/views/commercialInfo/index.vue'),
  },
  {
    path: '/rateTemplate',
    name: 'rateTemplate',
    component: () => import('@/views/rateTemplate/template/index.vue'),
  },
  {
    path: '/addTemplate',
    name: 'addTemplate',
    component: () => import('@/views/rateTemplate/addTemplate/index.vue'),
  },
  {
    path: '/standingSignMaintainList',
    name: 'standingSignMaintainList',
    component: () => import('@/views/standingSignMaintain/list/index.vue'),
  },
  {
    path: '/standingSignMaintainListDetail',
    name: 'standingSignMaintainListDetail',
    component: () => import('@/views/standingSignMaintain/list/detail.vue'),
  },
  {
    path: '/standingSignMaintainInfo',
    name: 'standingSignMaintainInfo',
    component: () => import('@/views/standingSignMaintain/info/index.vue'),
  },
  {
    path: '/standingSignMaintainInfo/basic',
    name: '/standingSignMaintainInfo/basic',
    component: () => import('@/views/standingSignMaintain/basic/index.vue'),
  },
  {
    path: '/standingSignMaintainInfo/account',
    name: '/standingSignMaintainInfo/account',
    component: () => import('@/views/standingSignMaintain/account/index.vue'),
  },
  {
    path: '/standingSignMaintainInfo/rate',
    name: '/standingSignMaintainInfo/rate',
    component: () => import('@/views/standingSignMaintain/rate/index.vue'),
  },
  {
    path: '/standingSignMaintainInfo/picture',
    name: '/standingSignMaintainInfo/picture',
    component: () => import('@/views/standingSignMaintain/picture/index.vue'),
  },
  {
    path: '/commercial/accountType',
    name: '/commercial/accountType',
    component: () => import('@/views/commercial/accountType.vue'),
  },
  {
    path: '/commercial/refuseInfo',
    name: '/commercial/refuseInfo',
    component: () => import('@/views/commercial/refuseInfo.vue'),
  },
  {
    path: '/application/merchant',
    name: '/application/merchant',
    component: () => import('@/views/home/merchant/index.vue'),
  },
  {
    path: '/application/expand',
    name: '/application/expand',
    component: () => import('@/views/home/merchant/expand.vue'),
  },
  {
    path: '/myMerchant/index',
    name: '/myMerchant/index',
    component: () => import('@/views/myMerchant/index.vue'),
  },
  {
    path: '/myMerchant/detail',
    name: '/myMerchant/detail',
    component: () => import('@/views/myMerchant/detail.vue'),
  },
  {
    path: '/merchantPurchase/index',
    name: '/merchantPurchase/index',
    component: () => import('@/views/merchantPurchase/index.vue'),
  },
  {
    path: '/drafts/index',
    name: '/drafts/index',
    component: () => import('@/views/drafts/index.vue'),
  },
  {
    path: '/channel/index',
    name: '/channel/index',
    component: () => import('@/views/channel/index.vue'),
  },
  //微信实名
  {
    path: '/weChatRealNameAut',
    name: 'weChatRealNameAut',
    component: () => import('@/views/weChatRealNameAut/index.vue'),
  },
  {
    path: '/busDetail',
    name: 'busDetail',
    component: () => import('@/views/weChatRealNameAut/busDetail.vue'),
  },
  {
    path: '/toBeConfirmed',
    name: 'toBeConfirmed',
    component: () => import('@/views/weChatRealNameAut/toBeConfirmed.vue'),
  },
  {
    path: '/RealNameAut',
    name: 'RealNameAut',
    component: () => import('@/views/weChatRealNameAut/realNameAut.vue'),
  },
  {
    path: '/RealNameAutTian',
    name: 'RealNameAutTian',
    component: () => import('@/views/weChatRealNameAut/realNameAutTian.vue'),
  },
  {
    path: '/smallRealNameAut',
    name: 'smallRealNameAut',
    component: () => import('@/views/weChatRealNameAut/smallRealNameAut.vue'),
  },
  {
    path: '/smallRealNameAutTian',
    name: 'smallRealNameAutTian',
    component: () =>
      import('@/views/weChatRealNameAut/smallRealNameAutTian.vue'),
  },
  //支付宝实名
  {
    path: '/AlipayRealNameAut',
    name: 'AlipayRealNameAut',
    component: () => import('@/views/AlipayRealNameAut/index.vue'),
  },
  {
    path: '/AlipaybusDetail',
    name: 'AlipaybusDetail',
    component: () => import('@/views/AlipayRealNameAut/busDetail.vue'),
  },
  {
    path: '/AlipaytoBeConfirmed',
    name: 'AlipaytoBeConfirmed',
    component: () => import('@/views/AlipayRealNameAut/toBeConfirmed.vue'),
  },
  {
    path: '/AlipayRealNameAUT',
    name: 'AlipayRealNameAUT',
    component: () => import('@/views/AlipayRealNameAut/realNameAut.vue'),
  },
  {
    path: '/AlipayRealNameAutTian',
    name: 'AlipayRealNameAutTian',
    component: () => import('@/views/AlipayRealNameAut/realNameAutTian.vue'),
  },
  {
    path: '/AlipaysmallRealNameAut',
    name: 'AlipaysmallRealNameAut',
    component: () => import('@/views/AlipayRealNameAut/smallRealNameAut.vue'),
  },
  {
    path: '/AlipaysmallRealNameAutTian',
    name: 'AlipaysmallRealNameAutTian',
    component: () =>
      import('@/views/AlipayRealNameAut/smallRealNameAutTian.vue'),
  },
  {
    path: '/industrialMerchants',
    name: 'IndustrialMerchants',
    component: () =>
        import('@/views/industrialMerchants/index.vue'),
  },
  {
    path: '/industrialMerchants/add',
    name: 'IndustrialMerchantsAdd',
    component: () =>
        import('@/views/industrialMerchants/add.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
