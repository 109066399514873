import router from "./index";
import store from "@/store/index";

const whiteList = ["/login"];

router.beforeEach((to, from, next) => {
  const token = store.state.login.token;

  if (token) {
    next();
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next("/login");
      // Toast.clear()
    }
  }
});

router.afterEach(() => {
  // Toast.clear()
});
