import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/index.less";
import Vant from "vant";
import { Toast } from "vant";
import { Button } from "vant";

import "vant/lib/index.css";
import "@/router/permission.js";
import VueClipboard from "vue-clipboard2";
import moment from "moment"
Vue.prototype.$moment = moment;

Vue.use(Vant);
Vue.use(Toast);
Vue.use(VueClipboard);
Vue.use(Button);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
