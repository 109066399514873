var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {}, [_c('router-view'), _c('van-tabbar', {
    staticClass: "tabbar",
    attrs: {
      "route": ""
    },
    on: {
      "change": _vm.changeActive
    },
    model: {
      value: _vm.$store.state.tabbar.active,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.tabbar, "active", $$v);
      },
      expression: "$store.state.tabbar.active"
    }
  }, [_c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/application/home"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function (props) {
        return [_c('van-icon', {
          attrs: {
            "name": props.active ? _vm.home : _vm.home1
          }
        })];
      }
    }])
  }, [_vm._v(" 首页")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/application/information"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function (props) {
        return [_c('van-icon', {
          attrs: {
            "name": props.active ? _vm.information1 : _vm.information
          }
        })];
      }
    }])
  }, [_vm._v(" 数据")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/application/use"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function (props) {
        return [_c('van-icon', {
          attrs: {
            "name": props.active ? _vm.use1 : _vm.use
          }
        })];
      }
    }])
  }, [_vm._v("应用")]), _c('van-tabbar-item', {
    attrs: {
      "replace": "",
      "to": "/application/my"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function (props) {
        return [_c('van-icon', {
          attrs: {
            "name": props.active ? _vm.my1 : _vm.my
          }
        })];
      }
    }])
  }, [_vm._v("我的")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };