import request from "@/utils/request"
import qs from "qs"

export function myTeam(data) {
  return request({
    url: "/index.php?m=Agent&c=Index&a=myTeam",
    method: "post",
    data: qs.stringify(data),
  })
}

export function teamMemberList(data) {
  return request({
    url: "/index.php?m=Agent&c=Index&a=teamMemberList",
    method: "post",
    data: qs.stringify(data),
  })
}

export function directlyAffiliatedMerchants(data) {
  return request({
    url: "/index.php?m=Agent&c=Index&a=directlyAffiliatedMerchants",
    method: "post",
    data: qs.stringify(data),
  })
}

export function realTimeData(data) {
  return request({
    url: "/index.php?m=Agent&c=Index&a=realTimeData",
    method: "post",
    data: qs.stringify(data),
  })
}
