export default {
  namespaced: true,
  state: () => {
    return {
      token: "",
      info: {}
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setSn(state, sn) {
      state.sn = sn
    },
    setMyInfo(state,info){
      state.info = info
    }
  },
}
