import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import tabbar from "./modules/tabbar";
import login from "./modules/login";
import warehouseentry from "./modules/warehouseentry";
import home from "./modules/home";
import standingSignMaintain from "./modules/standingSignMaintain";
import standing from "./modules/standing/thisData";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    tabbar,
    login,
    warehouseentry,
    home,
    standingSignMaintain,
    standing
  },
  plugins: [
    createPersistedState({
      key: "xiaolian", // 往本地存的键名
      paths: [
        "tabbar",
        "login",
        "warehouseentry",
        "home",
        "standingSignMaintain",
        "standing"
      ], // 哪个模块数据需要持久化到本地
    }),
  ],
});
