export default {
  namespaced: true,
  state: () => {
    return {
      active: 0,
    }
  },
  mutations: {
    updateActive(state, num) {
      state.active = num
    },
  },
}
