export default {
    namespaced: true,
    state: () => {
        return {
            info: {},
            basicInfo: {},
            basicPic: {},
            accountInfo: {},
            accountPic: {},
            rateInfo: {},
            picInfo: {},
        };
    },
    mutations: {
        setInfo(state, info) {
            state.info = info;
        },
        setBasicInfo(state, info) {
            state.basicInfo = info;
        },
        setBasicPic(state, basicPic) {
            state.basicPic = basicPic;
        },

        setAccountInfo(state, info) {
            state.accountInfo = info;
        },
        setAccountPic(state, accountPic) {
            state.accountPic = accountPic;
        },
        setRateInfo(state, info) {
            state.rateInfo = info;
        },
        setPicInfo(state, info) {
            state.picInfo = info;
        },
    },
};
