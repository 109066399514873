import { realTimeData } from "@/api/home/index"

export default {
  namespaced: true,
  state: () => {
    return {
      timeData: {},
    }
  },
  mutations: {
    setTimeData(state, timeData) {
      state.timeData = timeData
    },
  },
  actions: {
    async getRealTimeData({ commit }) {
      const { data } = await realTimeData()
      commit("setTimeData", data)
    },
  },
}
